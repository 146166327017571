import DetailDisplayItem from '@/common/components/DetailDisplayItem';
import { PartCustomFieldTypeEnum } from '@/graphql/types';
import { formatDateToMDHHmm_or_YYYYMDHHmm, formatDateToMD_or_YYYYMD } from '@/utils/date/date';
import { FC } from 'react';
import { usePartCustomFields } from '../hooks/usePartCustomFields';
import {
  IPartCustomField,
  IPartCustomFieldDateValue,
  IPartCustomFieldDatetimeValue,
  IPartCustomFieldFloatValue,
  IPartCustomFieldIntValue,
  IPartCustomFieldSelectValue,
  IPartCustomFieldTextValue,
} from '../types';

interface DisplayItem {
  customField: IPartCustomField;
  displayValue: string;
}

interface Props {
  textValues: IPartCustomFieldTextValue[];
  intValues: IPartCustomFieldIntValue[];
  floatValues: IPartCustomFieldFloatValue[];
  selectValues: IPartCustomFieldSelectValue[];
  dateValues: IPartCustomFieldDateValue[];
  datetimeValues: IPartCustomFieldDatetimeValue[];
}
export const PartDetailItemCustomFields: FC<Props> = (props) => {
  const { textValues, intValues, floatValues } = props;
  const { partCustomFields } = usePartCustomFields();

  const nestingValuesPerCustomField = (): DisplayItem[] => {
    const nestedValues: DisplayItem[] = [];

    partCustomFields.map((customField) => {
      if (customField.type === PartCustomFieldTypeEnum.Int) {
        const intValue = intValues.find((intValue) => intValue.customFieldId === customField.id);
        if (intValue) {
          nestedValues.push({
            customField,
            displayValue: intValue.value.toLocaleString(),
          });
        }
        return;
      }

      if (customField.type === PartCustomFieldTypeEnum.Float) {
        const floatValue = floatValues.find(
          (floatValue) => floatValue.customFieldId === customField.id
        );
        if (floatValue) {
          nestedValues.push({
            customField,
            displayValue: floatValue.value.toLocaleString(),
          });
        }
        return;
      }

      if (customField.type === PartCustomFieldTypeEnum.Text) {
        const textValue = textValues.find(
          (textValue) => textValue.customFieldId === customField.id
        );
        if (textValue) {
          nestedValues.push({
            customField,
            displayValue: textValue.value,
          });
        }
        return;
      }

      if (customField.type === PartCustomFieldTypeEnum.Select) {
        const isMulti = customField.customFieldSelectConfig?.multiple ?? false;
        const targetSelectValues = isMulti
          ? props.selectValues.filter((selectValue) => selectValue.customFieldId === customField.id)
          : [
              props.selectValues.find(
                (selectValue) => selectValue.customFieldId === customField.id
              ),
            ];

        if (targetSelectValues.length > 0) {
          const optionValue = customField.options[0];
          const { options = [] } = customField;
          const matchingLabels = targetSelectValues.map((selectValue) => {
            if (!selectValue) return '';

            const matchingOption = options.find((option) => option.id === selectValue.optionId);
            return matchingOption ? matchingOption.value : '';
          });
          if (optionValue) {
            nestedValues.push({
              customField,
              displayValue: matchingLabels.join(','),
            });
          }
        }
        return;
      }

      if (customField.type === PartCustomFieldTypeEnum.Date) {
        const dateValue = props.dateValues.find(
          (dateValue) => dateValue.customFieldId === customField.id
        );
        if (dateValue) {
          nestedValues.push({
            customField,
            displayValue: formatDateToMD_or_YYYYMD(dateValue.value),
          });
        }
        return;
      }

      if (customField.type === PartCustomFieldTypeEnum.Datetime) {
        const datetimeValue = props.datetimeValues.find(
          (datetimeValue) => datetimeValue.customFieldId === customField.id
        );
        if (datetimeValue) {
          nestedValues.push({
            customField,
            displayValue: formatDateToMDHHmm_or_YYYYMDHHmm(datetimeValue.value),
          });
        }
        return;
      }
    });

    return nestedValues;
  };

  const isVerticalDisplay = (customField: IPartCustomField) => {
    // テキストのみvertical。今後他に vertical 表示したいフィールドタイプが追加されたら条件追加。
    if (customField.type === PartCustomFieldTypeEnum.Text) {
      return true;
    }
    return false;
  };

  return (
    <>
      {nestingValuesPerCustomField().map((item) => (
        <DetailDisplayItem
          key={item.customField.id}
          label={item.customField.label}
          value={item.displayValue}
          vertical={isVerticalDisplay(item.customField)}
        />
      ))}
    </>
  );
};
