import DetailDisplayItem from '@/common/components/DetailDisplayItem';
import { DocumentProps, FileUploadZone } from '@/common/components/FileUploader';
import MediaSwiperWrapper from '@/common/components/MediaSwiperWrapper';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { DOC_ACCEPT_TYPES } from '@/common/constants';
import { useApplicationContext } from '@/context/ApplicationContext';
import { buildAssetDescription } from '@/modules/assets/utils';
import { FileContentType, FileIdAndUrl } from '@/utils/file/type';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { gql } from '@apollo/client';
import { Box, Tag, TagLabel, Text } from '@chakra-ui/react';
import { FC, lazy } from 'react';
import { useCurrencySymbol } from '../hooks/useCurrencySymbol';
import { formatUnit, isReplenishmentRequired } from '../utils';
import { PartDetailItemCustomFields } from './PartDetailItemCustomFields';
import type { PartDetailItems_PartFragment } from './PartDetailItems.generated';
const DocumentViewer = lazy(() => import('@/common/components/DocumentViewer'));

gql`
  fragment PartDetailItems_Part on Part {
    id
    description
    office {
      id
      name
    }
    area
    unit
    stock
    orderPoint
    cost
    currencyCode
    manufacturer
    model
    assets {
      id
      name
      parentAsset {
        id
        name
        parentAsset {
          id
          name
          parentAsset {
            id
            name
            parentAsset {
              id
              name
            }
          }
        }
      }
    }
    files {
      partId
      name
      contentType
      fileId
      path
      src
    }
    documents {
      id
      partId
      name
      contentType
      fileId
      path
      size
      url
      type
      src
    }
    # 一覧のthumbnailUrlを更新するために、使っていないが追加している。
    thumbnailUrl
    customFieldTextValues {
      customFieldId
      value
    }
    customFieldIntValues {
      customFieldId
      value
    }
    customFieldFloatValues {
      customFieldId
      value
    }
    customFieldSelectValues {
      customFieldId
      optionId
    }
    customFieldDatetimeValues {
      customFieldId
      value
    }
    customFieldDateValues {
      customFieldId
      value
    }
    groups {
      id
      name
    }
  }
`;

type PartDetailItemsProps = {
  part: PartDetailItems_PartFragment;
  showOfficeName?: boolean;
  getFileUploadUrls?: (filesContentTypes: FileContentType[]) => Promise<FileIdAndUrl[]>;
  onOpen?: () => void;
  onAddFile?: (input: DocumentProps) => void;
  onRemoveFile?: (fileId: string) => void;
  onAddDocument?: (input: DocumentProps) => void;
};

const PartDetailItems: FC<PartDetailItemsProps> = (props) => {
  const {
    part,
    onAddFile,
    getFileUploadUrls,
    onRemoveFile,
    onAddDocument,
    showOfficeName = false,
  } = props;
  const {
    office,
    description,
    area,
    unit,
    stock,
    orderPoint,
    cost,
    currencyCode,
    manufacturer,
    model,
    assets,
    files,
    documents,
    customFieldTextValues,
    customFieldIntValues,
    customFieldFloatValues,
    customFieldSelectValues,
    customFieldDateValues,
    customFieldDatetimeValues,
    groups,
  } = part;

  const isReplenishmentWarning = isReplenishmentRequired(stock, orderPoint);

  const { t } = useTranslation();
  const { combineCurrencySymbol } = useCurrencySymbol();
  const { isMobile } = useScreenInfos();
  const { companySetting } = useApplicationContext();

  return (
    <Box marginBottom={isMobile ? 14 : 0} bg='neutral.0' m={2} p={2} borderRadius='md'>
      <Box>
        {showOfficeName && <DetailDisplayItem value={office.name} />}
        <DetailDisplayItem label={t('stock.inventory')}>
          {stock !== null && (
            <>
              {isReplenishmentWarning && (
                <Tag size='md' backgroundColor='error.500' color='white' mr={3}>
                  <TagLabel>{t('replenish')}</TagLabel>
                </Tag>
              )}

              <Text fontSize='md' color={isReplenishmentWarning ? 'error' : 'neutral.800'}>
                {stock}
                {formatUnit(unit)}
              </Text>
            </>
          )}
        </DetailDisplayItem>
        <DetailDisplayItem
          label={t('stock.minimum-number')}
          value={orderPoint !== null ? `${orderPoint}${formatUnit(unit)}` : null}
        />
        <DetailDisplayItem
          label={t('unit-price')}
          value={cost && combineCurrencySymbol(cost, currencyCode)}
        />
        <DetailDisplayItem label={t('storage-location')} value={area} />
        <DetailDisplayItem label={t('manufacturer')} value={manufacturer} />
        <DetailDisplayItem label={t('part.number-model')} value={model} />
        <DetailDisplayItem label={t('description')} value={description} vertical={true} />
        {companySetting?.accessGroup && (
          <DetailDisplayItem
            label={t('group')}
            value={groups.map((group) => group.name).join(',')}
            data-testid='part-detail-group'
          />
        )}
        <PartDetailItemCustomFields
          textValues={customFieldTextValues}
          intValues={customFieldIntValues}
          floatValues={customFieldFloatValues}
          selectValues={customFieldSelectValues}
          dateValues={customFieldDateValues}
          datetimeValues={customFieldDatetimeValues}
        />
        <DetailDisplayItem
          label={t('pages.asset')}
          value={buildAssetDescription(assets)}
          vertical={true}
        />
        <DetailDisplayItem label={t('photo')} data-testid='part-detail-item-image' vertical={true}>
          {files.length === 0 && getFileUploadUrls && (
            <FileUploadZone
              acceptTypes={['image']}
              addFile={onAddFile}
              getFileUploadUrls={getFileUploadUrls}
            />
          )}

          {files.length > 0 && (
            <MediaSwiperWrapper
              mediaFiles={files}
              showRemoveFileIcon={!!onRemoveFile}
              removeFileHandler={(fileId) => onRemoveFile && onRemoveFile(fileId)}
              displayName={true}
            />
          )}
        </DetailDisplayItem>

        <DetailDisplayItem
          label={t('related-documents')}
          data-testid='part-detail-item-image'
          vertical={true}
        >
          {documents.length === 0 && getFileUploadUrls && (
            <FileUploadZone
              acceptTypes={DOC_ACCEPT_TYPES}
              addFile={onAddDocument}
              getFileUploadUrls={getFileUploadUrls}
            />
          )}

          {documents.length > 0 && (
            <SuspenseWithSpinner>
              <DocumentViewer mediaFiles={documents} showRemoveFileIcon={false} />
            </SuspenseWithSpinner>
          )}
        </DetailDisplayItem>
      </Box>
    </Box>
  );
};

export default PartDetailItems;
