import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { Box, Button, Text, TextProps, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import PartCard from './PartCard';
import type { PartCardList_PartsFragment } from './PartCardList.generated';

gql`
  fragment PartCardList_Parts on Part {
    ...PartCard_Part
  }
`;

export type PartCardListProps = {
  parts: PartCardList_PartsFragment[];
  totalCount: number;
  onPartClicked: (partId: number) => void;
  onPartDetailClicked?: (partId: number) => void;
  title?: string;
  titleProps?: TextProps;
  maxWidth?: string;
  targetPartId?: number;
  onLoadMoreClicked?: () => void;
  showOfficeName?: boolean;
};

const PartCardList: FC<PartCardListProps> = (props: PartCardListProps) => {
  const {
    parts,
    totalCount,
    onPartClicked,
    onPartDetailClicked,
    title,
    titleProps,
    maxWidth,
    targetPartId,
    onLoadMoreClicked,
    showOfficeName = false,
  } = props;

  const { t } = useTranslation();
  const displayCount = parts.length;
  const displayTitle = title ? title : `${t('sort-by')}: ${t('date.created-date')}`;
  const displayParts = displayCount ? parts.slice(0, displayCount) : parts;
  const showLoadMoreButton =
    displayCount !== undefined && totalCount !== undefined && displayCount < totalCount;

  return (
    <Box
      minW={{ base: 'full', md: '350px' }}
      maxWidth={maxWidth}
      overflowY='auto'
      borderRight='1px'
      borderColor='neutral.200'
    >
      <Text
        borderColor='neutral.200'
        color='neutral.500'
        fontSize='sm'
        px={2}
        py={1}
        {...titleProps}
      >
        {displayTitle}
      </Text>
      {displayParts.map((part) => (
        <PartCard
          key={part.id}
          {...part}
          isActive={part.id === targetPartId}
          onPartClicked={onPartClicked}
          onPartDetailClicked={onPartDetailClicked}
          showOfficeName={showOfficeName}
        />
      ))}
      {showLoadMoreButton && (
        <VStack w='full' mt={5} mb={{ base: '60px', md: '0' }}>
          <Button onClick={onLoadMoreClicked}>{t('show-more')}</Button>
          <Text>
            1 - {displayCount} / {totalCount}
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default PartCardList;
