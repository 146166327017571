import { AcceptType } from '../components/FileUploader';

export const DOC_ACCEPT_TYPES: AcceptType[] = [
  'image',
  'video',
  'pdf',
  'csv',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'txt',
  'link',
];
