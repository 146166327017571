import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';

import { gql } from '@apollo/client';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { type FC, useState } from 'react';
import PartDetailItems from './PartDetailItems';
import { usePartDetailModalSuspenseQuery } from './PartDetailModal.generated';

type Props = {
  isEdit?: boolean;
  partId: number;
  showOfficeName?: boolean;
};

gql`
  query PartDetailModal($id: Int!) {
    part(id: $id) {
      name
      ...PartDetailItems_Part
    }
  }
`;

const PartDetailModalContent: FC<Props> = (props: Props) => {
  const { partId, showOfficeName } = props;
  const {
    data: { part },
  } = usePartDetailModalSuspenseQuery({
    variables: { id: partId },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize='md' px={4} py={3}>
          {part.name}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody px={4} py={2} borderTop='1px' borderColor='neutral.300'>
          <PartDetailItems part={part} showOfficeName={showOfficeName} />
        </ModalBody>
      </ModalContent>
    </>
  );
};

export const usePartDetailModal = (): {
  open: (props: Props) => void;
  element: React.ReactElement;
} => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contentProps, setContentProps] = useState<Props | null>(null);

  return {
    open: (props) => {
      setContentProps(props);
      onOpen();
    },
    element: (
      <Modal
        onClose={onClose}
        size={{ base: 'full', md: 'lg' }}
        scrollBehavior='inside'
        isOpen={isOpen}
      >
        <SuspenseWithSpinner>
          {contentProps && <PartDetailModalContent {...contentProps} />}
        </SuspenseWithSpinner>
      </Modal>
    ),
  };
};
